import { historyState } from '../../mock-data/history-state';
import { PersonRequestData, RequestDeclineData, RequestEnrollmentData } from '../../types/requests';
import requestsApi from '../../lib/api/requests';
import serviceClassApi from '../../lib/api/service-class';

export const submitPersonRequestData = async (
  values: PersonRequestData,
  isChild: boolean,
  setLoadingCallback: (value: boolean) => void,
  setEditModeCallback: (value: boolean) => void,
  setEditModeParent: (value: string | null) => void,
  updateRequestCallback: () => void
) => {
  setLoadingCallback(true);
  try {
    values.isChild = isChild;
    await requestsApi.saveRequestPerson(values);
    setEditModeCallback(false);
    setEditModeParent(null);
    updateRequestCallback();
  } finally {
    setLoadingCallback(false);
    updateRequestCallback();
  }
};

export const submitRefusal = async (
  values: RequestDeclineData,
  requestId: number,
  setLoadingCallback: (value: boolean) => void,
  setShowModalCallback?: (value: boolean) => void,
  setEditModeCallback?: (value: boolean) => void,
  updateRequestCallback?: () => void
) => {
  setLoadingCallback(true);
  setShowModalCallback && setShowModalCallback(false);
  try {
    await requestsApi.rejectionRequest(requestId, values);
  } finally {
    setEditModeCallback && setEditModeCallback(false);
    setLoadingCallback(false);
    updateRequestCallback && updateRequestCallback();
  }
};

export const submitEnrollment = async (
  values: RequestEnrollmentData,
  requestId: number,
  setLoadingCallback: (value: boolean) => void,
  setShowModalCallback?: (value: boolean) => void,
  setEditModeCallback?: (value: boolean) => void,
  updateRequestCallback?: () => void
) => {
  setLoadingCallback(true);
  setShowModalCallback && setShowModalCallback(false);
  try {
    await requestsApi.enrollRequest(requestId, values);
  } finally {
    setEditModeCallback && setEditModeCallback(false);
    setLoadingCallback(false);
    updateRequestCallback && updateRequestCallback();
  }
};

export const checkStatus = async (
  serviceClassId: number,
  contingentGuid: string,
  setMessage: (value: string) => void,
  setLoadingCheck: (value: boolean) => void,
  setIntersection: (value: boolean) => void
) => {
  if (contingentGuid && serviceClassId) {
    setLoadingCheck(true);
    setMessage('');
    setIntersection(false);

    if (contingentGuid) {
      try {
        // setTimeout если очень быстрый ответ от сервера, то сообщение моргает, с этим выглядит лучше
        setTimeout(async () => {
          const response = await serviceClassApi.checkScheduleIntersection(serviceClassId, contingentGuid);

          if (!response) {
            setLoadingCheck(false);
            setIntersection(true);
            setMessage('');
          } else {
            setMessage(response.message);
            setIntersection(false);
            setLoadingCheck(false);
          }
        }, 2000);
      } catch (e) {
        setLoadingCheck(false);
      }
    }
  }
};

export const requestHistoryState = {
  ...historyState,
  searchType: 'searchType',
};
