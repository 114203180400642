import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { LmRadio } from '@mes-ui/lemma';
import { Link } from 'react-router-dom';
import Field, { FieldProps } from '../../../../../components/fields/field';
import { TrainingGroupByServiceDonm, TrainingGroupSCBooking } from '../../../../../types/requests';
import { formatDate } from '../../../../../lib/utils/date';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { serviceIdField } from '../service-donm';
import SimpleTable from '../../../../../components/table/simple-table';
import { routes } from '../../../../../config/constants';

type AdmissionPlanProps = FieldProps<TrainingGroupByServiceDonm[]>;

const AdmissionPlanService = ({ label = 'План приема', name, editMode, required, disabled }: AdmissionPlanProps) => {
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupSCBooking[] | null>(null);
  const [serviceField] = useField<number>(serviceIdField);
  const [field, , helpers] = useField(name);
  const [, , helpersId] = useField('trainingGroup.id');

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsSCBooking(serviceField.value ?? 0);

      setTrainingGroupList(tgList);
    };

    if (serviceField.value != null) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceField.value]);

  return trainingGroupList ? (
    <Field
      label={label}
      editMode={editMode}
      disabled={!editMode || disabled}
      required={required}
      value={trainingGroupList}
    >
      <SimpleTable
        data={trainingGroupList}
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',
            render: (item: any) => (
              <LmRadio
                dataTest="scheduleOfTimetableId"
                boxSize="large"
                checked={field.value === item.scheduleOfTimetableId}
                onChange={async () => {
                  helpers.setValue(item.scheduleOfTimetableId);
                  helpersId.setValue(item.id);
                }}
                disabled={!editMode || disabled}
                resetAllPadding
              />
            ),
            width: '52px',
          },
          {
            dataIndex: 'date',
            title: 'Дата начала занятий',
            render: (item: any) => formatDate(item.educationStartDate),
            width: '240px',
          },
          {
            dataIndex: 'serviceClassList',
            title: 'Код группы',
            render: (item: any) => (
              <>
                {item.serviceClassList?.map((serviceClass: any) => (
                  <div>
                    <Link
                      className="brand-link cursor-pointer"
                      to={`${routes.serviceClasses}/${serviceClass.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {serviceClass.code}
                    </Link>
                  </div>
                ))}
              </>
            ),
          },
          {
            dataIndex: 'numbers',
            title: 'Свободно/ Всего',
            render: (item: any) =>
              `${item.volume ? `${item.volume - item.volumeUsed}/${item.volume}` : 'без ограничений'}`,
            width: '151px',
          },
        ]}
      />
    </Field>
  ) : null;
};

export default AdmissionPlanService;
