import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, date as dateYup } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmIcon } from '@mes-ui/lemma';
import { addYears, addDays } from 'date-fns';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikToggle from '../../../../components/formik/formik-toggle';
import SavePanel from '../../../../components/save-panel';
import teacherApi from '../../../../lib/api/teacher';
import { addPhonePrefix, formatPhoneNumber } from '../../../../lib/utils/format-number';
import { phoneMask } from '../../../../lib/utils/mask';
import { notify } from '../../../../redux/ducks/notifications';
import TeacherDisciplines from './disciplines';
import PlaceOfWorks from './place-of-works';
import { emailRegexp, nullablePhone, rusRegexp } from '../../../../lib/utils/validation';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { PlaceOfWork, Teacher } from '../../../../types/teacher';
import { TeacherContext } from '../index';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { Discipline } from '../../../../types/discipline';
import { LoaderCustom } from '../../../../components/loader-custom';

type Props = {
  teacher: Teacher;
  onChange: (values: Teacher) => void;
};

const TeacherForm = ({ teacher, onChange }: Props) => {
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [editPersonalData, setEditPersonalData] = useState(false);
  const [randomKey, setRandomFieldKey] = useState(0);

  const context = useContext(TeacherContext);

  const [discipline, setDiscipline] = useState<Discipline[] | undefined>(teacher.discipline);
  const [placeOfWork, setPlaceOfWork] = useState<PlaceOfWork[] | undefined>(teacher.organization);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      ...teacher,
      phone: editPersonalData ? formatPhoneNumber(teacher.phone) || '' : teacher.phone,
      notPatronymic: !teacher.middleName,
    }),
    [teacher, editPersonalData]
  );

  const initialErrors = useInitialErrors(initialValues, getTeacherValidationSchema());

  const isAcceptedEdit = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Edit) && !teacher?.isFromRegister,
    [userProfile, teacher]
  );

  const access = hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Edit);

  const submitForm = useCallback(
    async ({ notPatronymic, ...values }: Teacher & { notPatronymic: boolean }, { setSubmitting }) => {
      delete values.discipline;
      delete values.organization;

      const response = await teacherApi.updateTeacher({
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        middleName: values.middleName?.trim(),
        discipline,
        organization: placeOfWork,
        ...values,
        phone: values.phone && addPhonePrefix(values.phone),
      });

      dispatch(
        notify.success({
          dataTest: 'saveInfoTeacher',
          title: 'Данные успешно сохранены',
        })
      );

      setEditPersonalData(false);
      onChange(response);
      setSubmitting(false);
    },
    [discipline, placeOfWork, onChange, dispatch]
  );

  const handleCancel = (resetForm: () => void) => () => {
    resetForm();
    setRerenderFormKey(Math.random());
    setEditPersonalData(false);
  };

  return context?.loading ? (
    <LoaderCustom
      size={180}
      hasPanel
    />
  ) : (
    <>
      <Formik
        enableReinitialize
        onSubmit={submitForm}
        key={rerenderFormKey}
        initialErrors={initialErrors}
        validationSchema={getTeacherValidationSchema()}
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<Teacher & { notPatronymic: boolean }>) => {
          const { handleSubmit, submitForm, isSubmitting, isValid, values, setFieldValue, resetForm } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <Panel
                title={() => 'Персональные данные'}
                headingControl={() =>
                  access &&
                  !editPersonalData && (
                    <button
                      type="button"
                      onClick={() => setEditPersonalData(true)}
                      className="icon-group"
                    >
                      <span className="icon-group__icon">
                        <LmIcon
                          icon="filled-edit-edit"
                          size={20}
                          color="var(--LM-blue-200)"
                        />
                      </span>
                      <span className="icon-group__text color-primary">
                        <b>Редактировать</b>
                      </span>
                    </button>
                  )
                }
              >
                <div className="container">
                  <div className="table-data">
                    <div
                      className="table-data__item table-data__group"
                      data-test="ФИО преподавателя"
                    >
                      <div className="table-data__label table-data__label--main">
                        ФИО преподавателя {editPersonalData ? <div className="table-data__required" /> : null}
                      </div>
                      <div className="table-data__body">
                        {editPersonalData ? (
                          <div className="table-data-grid-4 items-start">
                            <FormikInput
                              size="small"
                              placeholder="Фамилия"
                              name="lastName"
                              disabled={!isAcceptedEdit}
                            />
                            <FormikInput
                              size="small"
                              placeholder="Имя"
                              name="firstName"
                              disabled={!isAcceptedEdit}
                            />
                            <FormikInput
                              key={randomKey}
                              size="small"
                              placeholder="Отчество"
                              name="middleName"
                              disabled={!isAcceptedEdit || values.notPatronymic}
                            />
                            <div>
                              <FormikCheckbox
                                onChange={() => {
                                  setTimeout(() => {
                                    setFieldValue('middleName', '');
                                    setRandomFieldKey(Math.random());
                                  }, 0);
                                }}
                                label="Нет отчества"
                                name="notPatronymic"
                                boxSize="small"
                                disabled={!isAcceptedEdit}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-between">
                            <span data-test="teacherName">
                              {values.lastName} {values.firstName} {values.middleName}
                            </span>{' '}
                            {values.isFromRegister ? (
                              <div className="icon-group">
                                <span className="icon-group__icon">
                                  <LmIcon
                                    icon="outline-notifications-info"
                                    size={18}
                                    color="var(--LM-green-200)"
                                  />
                                </span>
                                <span className="icon-group__text color-success-dark">
                                  Сведения найдены в Реестре преподавателей
                                </span>
                              </div>
                            ) : (
                              <div className="icon-group">
                                <span className="icon-group__icon">
                                  <LmIcon
                                    icon="outline-edit-close-circle"
                                    size={18}
                                    color="var(--LM-red-200)"
                                  />
                                </span>
                                <span className="icon-group__text color-danger-dark">
                                  Сведения не найдены в Реестре преподавателей
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="table-data__item table-data__group">
                      <FormikFormGroup
                        name="birthDate"
                        horizontal
                        label="Дата рождения"
                      >
                        <>
                          {editPersonalData ? (
                            <FormikDatePicker
                              size="small"
                              name="birthDate"
                              placeholder="ДД.ММ.ГГГГ"
                              disabled={!isAcceptedEdit}
                            />
                          ) : values.birthDate ? (
                            new Date(values.birthDate).toLocaleDateString('ru-RU', {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric',
                            })
                          ) : (
                            '-'
                          )}
                        </>
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item table-data__group">
                      <FormikFormGroup
                        name=""
                        horizontal
                        label="Номер телефона"
                      >
                        <>
                          {editPersonalData ? (
                            <FormikInput
                              size="small"
                              disabled={!isAcceptedEdit}
                              maskRegex={phoneMask}
                              placeholder="(ХХХ) ХХХ-ХХ-ХХ"
                              leftPrefix="+7"
                              name="phone"
                            />
                          ) : (
                            values.phone || '-'
                          )}
                        </>
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup
                        name=""
                        horizontal
                        label="Адрес электронной почты"
                      >
                        {editPersonalData ? (
                          <FormikInput
                            size="small"
                            disabled={!isAcceptedEdit}
                            placeholder="Введите адрес электронной почты"
                            name="email"
                          />
                        ) : (
                          <>{values.email || '-'}</>
                        )}
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup
                        label="Контактное лицо"
                        horizontal
                        name="isContactPerson"
                      >
                        <FormikToggle
                          name="isContactPerson"
                          disabled={!access || !editPersonalData}
                          size="small"
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                </div>
              </Panel>

              {editPersonalData && (
                <SavePanel
                  primaryButtonModifiers={{
                    loading: isSubmitting,
                    disabled: !isValid || !access,
                  }}
                  onClickSeconadaryButton={handleCancel(resetForm)}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>

      {!editPersonalData && (
        <>
          <Push size={12} />
          <TeacherDisciplines
            values={initialValues}
            discipline={discipline}
            setDiscipline={setDiscipline}
          />

          <Push size={12} />
          <PlaceOfWorks
            values={initialValues}
            placeOfWork={placeOfWork}
            setPlaceOfWork={setPlaceOfWork}
            isAcceptedEdit={isAcceptedEdit}
          />
        </>
      )}
    </>
  );
};

export default TeacherForm;

const getTeacherValidationSchema = () =>
  objectYup().shape({
    lastName: stringYup()
      .required('Введите фамилию')
      .matches(rusRegexp, {
        message: 'Фамилия может содержать только русские буквы',
      })
      .nullable(),
    firstName: stringYup()
      .required('Введите имя')
      .matches(rusRegexp, {
        message: 'Имя может содержать только русские буквы',
      })
      .nullable(),
    middleName: stringYup()
      .nullable()
      .when('notPatronymic', {
        is: false,
        then: stringYup()
          .matches(rusRegexp, {
            message: 'Отчество может содержать только русские буквы',
          })
          .nullable()
          .required('Введите отчество'),
      }),
    birthDate: dateYup()
      .max(new Date(addYears(Date.now(), -18)), 'Учителю не может быть меньше 18 лет')
      .min(new Date(addDays(addYears(Date.now(), -100), -1)), 'Учителю не может быть больше 100 лет')
      .nullable()
      .optional(),

    email: stringYup()
      .matches(emailRegexp, {
        message: 'Указан неверный адрес электронной почты',
      })
      .nullable(),
    phone: nullablePhone,
  });
