import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { number as numberYup, object as objectYup } from 'yup';
import moment from 'moment';
import { Panel, Push } from '@mosru/esz_uikit';
import { LmSubHeader } from '@mes-ui/lemma';
import { addPhonePrefix } from '../../../../lib/utils/format-number';
import SavePanel from '../../../../components/save-panel';
import { notify } from '../../../../redux/ducks/notifications';
import FIO from '../../components/fields/fio';
import BirthDate from '../../components/fields/birthdate';
import Gender from '../../../../components/fields/gender';
import DocumentNumber from '../../components/fields/document-number';
import Document from '../../components/fields/document';
import Issued from '../../components/fields/issued';
import Snils from '../../components/fields/snils';
import MoscowRegistration from '../../components/fields/moscow-registration';
import Street from '../../components/fields/street';
import BuildingInfo from '../../components/fields/building-info';
import Apartment from '../../components/fields/apartment';
import District from '../../components/fields/district';
import Territory from '../../components/fields/territory';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import { EducationTypeEnum } from '../../../../types/education-type';
import ServiceDonm from '../../components/fields/service-donm';
import { formatDate } from '../../../../lib/utils/date';
import { RequestData } from '../../../../types/requests';
import requestsApi from '../../../../lib/api/requests';
import { generateLink, getServiceEditLink, redirect } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import {
  birthRecordIssued,
  childrenAssociation,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  nullableDate,
  nullableEmail,
  nullableMaxRusStringWithSlash,
  nullableMaxRusWithSpaceAndNumberString,
  nullableRus,
  phone,
  scheduleOfTimetable,
  sexId,
  snils,
  stringRequired,
} from '../../../../lib/utils/validation';
import { RequestContext } from '../../../../lib/utils/requests';
import WrapperFields from '../../components/fields/wrapper-fields';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import { StatusChild } from '../../components/status-child';
import { BookingResultData } from '../../../../types/booking';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import ServiceClasses from '../../components/fields/service-and-group-info/service-classes';
import { LoaderCustom } from '../../../../components/loader-custom';
import AdmissionPlanService from '../../components/fields/service-and-group-info/admission-plan-service';

type Props = {
  educationType: EducationTypeEnum;
  request?: RequestData;
  loading?: boolean;
  serviceInfoReadOnly?: boolean;
};

const DonmRequestCreate: React.FC<Props> = ({ educationType, request, loading, serviceInfoReadOnly }) => {
  const [load, setLoad] = useState<boolean>(false);
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [booking, setBooking] = useState<BookingResultData | undefined>(
    request
      ? {
          bookingId: request.trainingGroup.bookingGuid,
          slotId: request.trainingGroup.slotId,
        }
      : undefined
  );
  const { adminEdit } = useContext(RequestContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const time = (t: number) => setTimeout(() => setLoad(true), t);

    if (loading) {
      time(1500);
    } else {
      time(500);
    }
  }, [loading]);

  const initialValue = useMemo(() => {
    let values: RequestData | undefined = request;

    const applicantDocumentDefault = {
      documentTypeId: DocumentTypeEnum.Passport,
      documentTypeName: 'Паспорт гражданина РФ',
    };

    if (values && !values.applicant?.documentTypeId) {
      values.applicant = {
        ...values.applicant,
        ...applicantDocumentDefault,
      };
    }

    if (values && values?.child && values?.child.sexId === undefined) {
      values = {
        ...values,
        ...{
          child: {
            ...values.child,
            sexId: 1,
          },
        },
      } as RequestData;
    }

    if (values && values.applicant === null) {
      values = {
        ...values,
        ...{
          applicant: applicantDocumentDefault,
        },
      } as RequestData;
    }

    return (
      values ??
      ({
        child: {
          sexId: 1,
          firstName: '',
          lastName: '',
          middleName: '',
          series: '',
          sexName: 'Мужской',
          documentTypeId: DocumentTypeEnum.BirthCertificate,
          documentTypeName: 'Свидетельство о рождении',
        },
        applicant: applicantDocumentDefault,
      } as unknown as RequestData)
    );
  }, [request]);

  const submitForm = useCallback(
    async (values: RequestData) => {
      const fetch = async () => {
        setLoadBtn(true);
        try {
          if (booking) {
            values.trainingGroup.bookingGuid = booking.bookingId;
            values.trainingGroup.slotId = booking.slotId;
            values.educationTypeId = educationType;
            values.child.isChild = true;
            values.child.educationTypeId = educationType;
            values.applicant.isChild = false;
            values.applicant.educationTypeId = educationType;
            values.applicant.phone = addPhonePrefix(values.applicant.phone);

            const regAddress =
              values.regAddress && Object.values(values.regAddress).some((item) => item)
                ? {
                    ...values.regAddress,
                    id: 0,
                    isRegAddress: true,
                    educationTypeId: educationType,
                  }
                : null;

            const requestId = await requestsApi.createRequest({
              ...values,
              id: 0,
              dateRequest: moment.utc(new Date()).format(),
              regAddress,
              ...{
                child: {
                  ...values.child,
                  id: 0,
                },
              },
              ...{
                applicant: {
                  ...values.applicant,
                  id: 0,
                },
              },
            });

            history.push(
              generateLink(routes.request, {
                id: requestId,
              })
            );
            setLoadBtn(false);
          }
        } catch (ex: any) {
          dispatch(
            notify.danger({
              dataTest: 'createRequest',
              title: ex.data.message,
            })
          );
        }
      };

      fetch();
    },
    [educationType, booking, dispatch]
  );

  const back = () => history.push(routes.requests);

  const backDO = () =>
    redirect(getServiceEditLink(request?.educationTypeId ?? educationType, request?.trainingGroup?.serviceId));

  const loadingTemplate = (padding = 20) => (
    <LoaderCustom
      size={padding}
      hasPanel
    />
  );

  return (
    <>
      <LmSubHeader
        sticky
        arrowOnClick={back}
        description={
          <>
            <Push size={12} />
            <div className="flex">
              Дата заявления: {formatDate(new Date())}
              <Push
                size={20}
                orientation="horizontal"
              />
              Источник: Оператор
            </div>
          </>
        }
        title="Новое заявление"
        dataTest="requestDonmCreateSubHeader"
        routes={[
          {
            label: 'Главная',
            link: routes.main,
          },
          {
            label: 'Заявления',
            link: routes.requests,
          },
          {
            label: 'Новое заявление',
          },
        ]}
        buttonsOrAnyActions={
          serviceInfoReadOnly
            ? [
                {
                  dataTest: 'goToService',
                  buttonText: 'Вернуться к ДО',
                  rounded: 'small',
                  variant: 'secondary',
                  onClick: backDO,
                },
              ]
            : undefined
        }
      />
      <Formik
        validateOnMount
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValue}
        validationSchema={getValidationSchema}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { handleSubmit, submitForm, isValid, values } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <Panel title={() => 'Информация о ребенке'}>
                {load ? (
                  <div className="container">
                    <div className="table-data">
                      <StatusChild contingentLinkTypeId={values.contingentLinkTypeId} />

                      <WrapperFields />
                      <Gender
                        editMode
                        required
                        name="child.sex"
                      />
                      <WrapperFieldsDocument showErrorImmediately={!!initialValue.child?.number} />
                      <Snils
                        disabled={!adminEdit}
                        name="child.snils"
                        editMode
                      />
                      <MoscowRegistration
                        name="child.moscowRegistration"
                        editMode
                      />
                    </div>
                  </div>
                ) : (
                  loadingTemplate(200)
                )}
              </Panel>
              <Push size={12} />
              {educationType === EducationTypeEnum.ChildrenEducation && (
                <Panel title={() => 'Информация об адресе регистрации по месту жительства ребенка'}>
                  {load ? (
                    <div className="container">
                      <div className="table-data">
                        <Street
                          parent="regAddress"
                          name="street"
                          editMode
                        />
                        <BuildingInfo
                          parent="regAddress"
                          name=""
                          editMode
                        />
                        <Apartment
                          name="regAddress.roomNumber"
                          editMode
                        />
                        <District
                          parent="regAddress"
                          name="district"
                          editMode
                        />
                        <Territory
                          parent="regAddress"
                          name="area"
                          editMode
                        />
                      </div>
                    </div>
                  ) : (
                    loadingTemplate(100)
                  )}
                </Panel>
              )}
              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                {load ? (
                  <div className="container">
                    <div className="table-data">
                      <FIO
                        label="ФИО заявителя"
                        parent="applicant"
                        required
                        editMode
                      />
                      <BirthDate
                        name="applicant.dateOfBirth"
                        searchButton={false}
                        editMode
                      />
                      <Phone
                        name="applicant.phone"
                        editMode
                        required
                        showErrorImmediately={!!initialValue.applicant?.phone}
                      />
                      <Email
                        name="applicant.email"
                        editMode
                      />
                      <Document
                        dependentFields
                        name="documentType"
                        editMode
                        parent="applicant"
                        formType={FormTypeEnum.EditRequestApplicant}
                      />
                      <Issued
                        name="applicant.issued"
                        editMode
                      />
                      <DocumentNumber
                        parent="applicant"
                        editMode
                        calendarPosition="top-end"
                      />
                    </div>
                  </div>
                ) : (
                  loadingTemplate(150)
                )}
              </Panel>
              <Push size={12} />
              <Panel title={() => <>Информация о детском объединении и группе обучения</>}>
                {load ? (
                  <div className="container">
                    <div className="table-data">
                      <ServiceDonm
                        parent="trainingGroup"
                        name="service"
                        disabled={serviceInfoReadOnly}
                        editMode
                        educationType={educationType}
                        required
                        menuPlacement="top"
                      />
                      {values.trainingGroup?.serviceId && (
                        <AdmissionPlanService
                          name="trainingGroup.scheduleOfTimetableId"
                          disabled={serviceInfoReadOnly}
                          editMode
                          required
                        />
                      )}
                      {values.trainingGroup?.scheduleOfTimetableId && (
                        <ServiceClasses
                          contingentGuid={values.contingentGuid}
                          serviceId={values.trainingGroup?.serviceId}
                          setBooking={setBooking}
                          trainingGroupId={values.trainingGroup.id || values.trainingGroup.trainingGroupId}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  loadingTemplate()
                )}
              </Panel>
              {load && (
                <SavePanel
                  buttonPrimaryText="Создать заявление"
                  primaryButtonModifiers={{
                    loading: loadBtn,
                    disabled: !isValid || !booking,
                  }}
                  onClickSeconadaryButton={back}
                  onClickPrimaryButton={submitForm}
                />
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    regAddress: addressValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupValidationSchema,
  });

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName: nullableRus,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  snils,
  issued: birthRecordIssued,
});

export const addressValidationSchema = objectYup()
  .shape({
    houseNumber: nullableMaxRusStringWithSlash(20),
    housingNumber: nullableMaxRusStringWithSlash(20),
    ownershipNumber: nullableMaxRusStringWithSlash(20),
    buildingNumber: nullableMaxRusStringWithSlash(20),
    streetName: nullableMaxRusWithSpaceAndNumberString,
    roomNumber: nullableMaxRusStringWithSlash(20),
  })
  .nullable();

export const applicantValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName: nullableRus,
  dateOfBirth: nullableDate,
  email: nullableEmail,
  phone,
  documentTypeId: stringRequired,
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
  dateOfIssue: dateOfIssue(),
});

export const trainingGroupValidationSchema = objectYup().shape({
  serviceName: childrenAssociation,
  scheduleOfTimetableId: scheduleOfTimetable,
  serviceClass: objectYup({
    id: numberYup().required('Укажите группу по уходу и присмотру за детьми школьного возраста'),
  }),
});

export default DonmRequestCreate;
